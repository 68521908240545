<template lang="pug">
.listgames
  //- Listado de categorías
  GroupCategories(:categories="categories")
  
  //- Listado de juegos de una categoría
  v-container
    //- h2 {{ translations.title }}
    h2.listgames__title(v-if="category")
      v-chip.pa-1(color="primary", outlined, label)
        v-icon(size="24", color="primary") mdi-{{ getIcon(category.icon) }}
      span.ml-4 {{ nameLanguage(category.names) }}
    .listgames__group
      .listgames__item(
        v-for="game in gamesToShow"
        :key="game.id")
        CardGame(
          :title="game.name",
          :subtitle="game.type",
          :image="game.image"
          :gameId="game.vg"
          :fun="game.fun")
    div(v-intersect="onIntersect")
</template>

<script>
import { mapState } from "vuex"
import CardGame from "@/components/CardGame"
import GroupCategories from "@/components/GroupCategories"

export default {
  name: "CategoryGames",
  components: {
    CardGame,
    GroupCategories,
  },
  data: () => ({
    games: [],
    items: 1,
    itemsPerPage: 20,
    showing: false,
    category: undefined,
  }),
  computed: {
    ...mapState(['lobbyId', 'categories', 'categoryId']),
    translations() {
      return this.$t('views.Provider')
    },
    gamesToShow() {
      return this.category ? this.category.games.slice(0, this.items) : []
    }
  },
  watch: {
    categoryId: function (val) {
      this.category = this.categories.find( category => category.id === val )
    },
  },
  created() {
    if(this.categoryId) {
      this.category = this.categories.find( category => category.id === this.categoryId )
    } else {
      this.$router.push({name:'Home', params: { lobbyId: this.lobbyId } })
    }
  },
  methods: {
    nameLanguage(nameList) {
      let nameToShow = ''
      if (nameList) {
        if (nameList.length === 1) {
          nameToShow = nameList[0].text
        } else {
          const candidate = nameList.find(name => this.$i18n.locale.localeCompare(name.language.toLowerCase()) === 0 )
          if (candidate) {
            nameToShow = candidate.text
          }
        }
      }
      return nameToShow
    },
    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.items += this.itemsPerPage
      }
    },
    getIcon(icon) {
      return icon && icon.length > 1 ? icon : 'billiards'
    },
  },
};
</script>

<style lang="sass">
</style>
