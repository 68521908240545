var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "listgames" },
    [
      _c("GroupCategories", { attrs: { categories: _vm.categories } }),
      _c("v-container", [
        _vm.category
          ? _c(
              "h2",
              { staticClass: "listgames__title" },
              [
                _c(
                  "v-chip",
                  {
                    staticClass: "pa-1",
                    attrs: { color: "primary", outlined: "", label: "" }
                  },
                  [
                    _c("v-icon", { attrs: { size: "24", color: "primary" } }, [
                      _vm._v("mdi-" + _vm._s(_vm.getIcon(_vm.category.icon)))
                    ])
                  ],
                  1
                ),
                _c("span", { staticClass: "ml-4" }, [
                  _vm._v(_vm._s(_vm.nameLanguage(_vm.category.names)))
                ])
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "listgames__group" },
          _vm._l(_vm.gamesToShow, function(game) {
            return _c(
              "div",
              { key: game.id, staticClass: "listgames__item" },
              [
                _c("CardGame", {
                  attrs: {
                    title: game.name,
                    subtitle: game.type,
                    image: game.image,
                    gameId: game.vg,
                    fun: game.fun
                  }
                })
              ],
              1
            )
          }),
          0
        ),
        _c("div", {
          directives: [
            {
              name: "intersect",
              rawName: "v-intersect",
              value: _vm.onIntersect,
              expression: "onIntersect"
            }
          ]
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }